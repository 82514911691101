<template>
    <button type="submit" @click.stop.prevent="submit" :class="classes">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Let op!'
            },
            text: {
                type: String
            }
        },
        data () {
            return {
                classes: this.$attrs['class']
            }
        },
        methods: {
            submit(event) {
                this.$confirm(this.text, this.title).then(() => {
                    window.$(event.target).parents('form').submit();
                }).catch(() => {});
            }
        }
    }
</script>