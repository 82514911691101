<template>
    <div>
        <v-select label="label"
                  :filterable="false"
                  @search="search"
                  :options="results"
                  :input-id="id"
                  :placeholder="placeholder"
                  v-model="selected"
        >
            <div slot="spinner">
                <div class="spinner">{{ $t('Laden..') }}</div>
            </div>
            <span slot="no-options">{{ $t('Geen bedrijf gevonden') }}</span>
        </v-select>

        <input type="hidden" name="kvk" :value="selected ? selected.id : searchQuery"/>
        <input type="hidden" name="name" :value="selected ? selected.trade_name : ''"/>
    </div>
</template>
<script>
    export default {
        props: ['id', 'placeholder'],
        data () {
            return {
                results: [],
                selected: null,
                searchQuery: ''
            }
        },
        methods: {
            search (query, loading) {
                loading(true);
                this.fetch(query, loading, this);
            },
            fetch: _.debounce((query, loading, vm) => {
                window.axios('/api/kvk', {
                    params: {
                        query
                    }
                }).then((resp) => {
                    loading(false);
                    vm.results = _.map(resp.data, (option) => {
                        let label = option.trade_name ? option.id + ' - ' + option.trade_name : option.id.toString();
                        return Object.assign(option, {label})
                    });
                }).catch(() => {
                    loading(false);
                });
            }, 500)
        }
    }
</script>