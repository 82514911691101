/**
 * Init Bootstrap Table.
 * @param {string} id
 * @param {object} table
 * @param {Boolean} refresh
 */
window.initBootstrapTable = function(id, table, refresh) {
    if (refresh) {
        $("#"+id).bootstrapTable("destroy");
    }
    $("#"+id).bootstrapTable({
        url: table.url,
        iconsPrefix: " ", // must be empty, default is "bi", and "bi far fa-sync" does not work.
        icons: {
            paginationSwitchDown: 'far fa-angle-down',
            paginationSwitchUp: 'far fa-angle-up',
            refresh: 'far fa-sync',
            toggleOff: 'far fa-toggle-off',
            toggleOn: 'far fa-toggle-on',
            columns: 'far fa-columns',
            detailOpen: 'far fa-lg fa-plus-square',
            detailClose: 'far fa-lg fa-minus-square',
            clearSearch: 'far fa-times',
        },
        paginationPreText: '<span class="fal fa-chevron-left"></span>',
        paginationNextText: '<span class="fal fa-chevron-right"></span>',
        queryParams: function(params) {
            var r = {
                limit: params.limit,
                offset: params.offset,
                order: params.order,
                search: params.search,
                sort: params.sort
            };
            if (typeof table.ts === "undefined") {
                r.ts = new Date().getTime();
            }
            if (typeof table.filters !== "undefined") {
                var filters = {};
                $.each(table.filters, function(index, filter) {
                    var f = $("#" + filter);
                    if (typeof f.attr("multiple") !== "undefined" && f.attr("multiple") !== false) {
                        var multiple = [];
                        $("#" + filter + " :selected").each(function(index2, selected) {
                            let val = $(selected).val();
                            multiple.push(val);
                        });
                        filters[filter] = JSON.stringify(multiple);
                    } else {
                        filters[filter] = f.val();
                    }
                });
                var r = $.extend({}, r, filters);
            }
            return r;
        },
        onRefresh: function() {
            NProgress.start();
        },
        onPageChange: function() {
            NProgress.start();
        },
        onPreBody: function() {
            NProgress.start();
        },
        onPostBody: function () {
            if (typeof table.search_input_focus !== typeof undefined) {
                $(".search-input").focus();
            }
        },
        onLoadSuccess: function() {
            // Tooltip
            $('[data-toggle="tooltip"]').tooltip();
            // Callback
            if (typeof table.callback !== typeof undefined) {
                var fn = window[table.callback];
                if (typeof fn === "function") {
                    fn();
                }
            }
            // NProgress
            NProgress.done();
        },
        onLoadError: function(status, xhr) {
            Swal.fire({
                icon: "error",
                title: xhr.responseJSON.message,
                showConfirmButton: true,
                customClass: {
                    confirmButton: "bg-info"
                }
            });
        }
    });
    if (typeof table.sortable !== "undefined") {
        $("table#"+id+" tbody").sortable({
            handle: ".sortable",
            update: function(event, ui) {
                var _token = $("input[name=_token]").val();
                var data = $("table#"+id+" tbody i.sortable").map(function() {
                    return $(this).attr("data-id");
                }).get();
                $.ajax({
                    data: {
                        _token: _token,
                        data: data
                    },
                    type: "POST",
                    url: table.sortable.url
                });
            }
        });
    }
}

/**
 * Init Bootstrap Tables.
 */
window.initBootstrapTables = function() {
    if (typeof tables == "undefined") {
        return false;
    }
    $.each(tables, function(id, table) {
        initBootstrapTable(id, table, false);
    });
}

/**
 * Loading Template.
 * @returns {string}
 */
window.loadingTemplate = function() {
    return '<div class="loading-template"><i class="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse"></i></div>';
};

/**
 * Refresh Bootstrap Table.
 * @param {string} id
 */
window.refreshBootstrapTable = function(id) {
    // var table = tables[id];
    // initBootstrapTable(id, table, true);
    $("#"+id).bootstrapTable("refresh");
}