/**
 * Translate.
 * @param {string} string
 * @returns {*}
 */
window.__ = function(string) {
    if (typeof translations !== "undefined") {
        return translations[string] ? translations[string] : string;
    } else {
        return string;
    }
};
