<template>
    <div>
        <div class="holder">
            <h2 class="holder-title">Opdrachtgevers die jou een kanjer vinden</h2>
            <div class="row">
                <div class="col-sm-6 col-lg-6 col-xl-3" v-for="result in results">
                    <div class="card mb-4">
                        <img class="card-img-top" :src="result.impression_picture" :alt="result.companyName">

                        <div v-if="result.approved === 0">
                            <div class="card-orange-top-left">
                                Uitgenodigd
                            </div>
                        </div>

                        <div class="card-body">
                            <h5 class="card-title">{{ result.companyName }}</h5>
                            <h6 class="card-subtitle card-subtitle-function mb-3">{{ result.professionName }}</h6>
                            <div class="card-list-info">
                                <div class="d-flex">
                                    <div class="icon mr-2">
                                        <i class="far fa-user"></i>
                                    </div>
                                    <div class="text">{{ result.sameFavoriteCount }} freelancer(s) in de poel</div>
                                </div>
                            </div>
                            <div v-if="result.approved === 0">
                                <div class="mt-3">
                                    <form method="POST" :action="result.update_route">
                                        <input type="hidden" name="_method" value="PUT">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <input type="hidden" name="approved" value="1">
                                        <confirm-submit
                                                ref="subscribeButton"
                                                text="Weet je zeker dat de uitnodiging wilt accepteren."
                                                class="btn btn-orange btn-lg w-100"
                                                type="submit">
                                            {{ $t('Accepteren') }}
                                        </confirm-submit>
                                    </form>
                                </div>
                                <div class="mt-3">
                                    <form method="POST" :action="result.destroy_route">
                                        <input type="hidden" name="_method" value="DELETE">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <confirm-submit
                                                ref="subscribeButton"
                                                text="Weet je zeker dat de uitnodiging wilt afwijzen."
                                                class="btn btn-gray btn-lg w-100"
                                                type="submit">
                                            {{ $t('Afwijzen') }}
                                        </confirm-submit>
                                    </form>
                                </div>
                            </div>
                            <div v-else>
                                <div class="mt-3">
                                    <form method="POST" :action="result.destroy_route">
                                        <input type="hidden" name="_method" value="DELETE">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <confirm-submit
                                                ref="subscribeButton"
                                                text="Weet je zeker dat je jezelf wilt verwijderen als favoriet?"
                                                class="btn btn-orange btn-lg w-100"
                                                type="submit">
                                            {{ $t('Verwijderen') }}
                                        </confirm-submit>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'favorites',
            'route',
        ],
        data () {
            return {
                results: null,
                id: null,
                companyName: null,
                formRoute: null,
                approve: 1,
                csrf: window.csrfToken,
            }
        },
        mounted () {
            this.results = this.favorites
        }
    }
</script>