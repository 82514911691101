<template>
    <div class="row holder-row-top-fields">
        <div class="col-xl-3">
            <div class="form-group form-group-lg">
                <label for="company_name">Opdrachtgever</label>
                <input type="text" class="form-control" id="company_name" :placeholder="$t('Zoeken op naam')" v-model="query" @input="queryChange">
            </div>
        </div>
        <div class="col-xl-3">
            <div class="form-group form-group-lg">
                <label for="distance">Zoeken op locatie</label>
                <div class="d-flex">
                    <postalcode-input :value="location" @input="(value) => Object.assign(location, value)" class="form-control col-6 mr-2" id="distance"></postalcode-input>
                    <select v-model="distance" class="form-control ml-2 col-6">
                        <option :value="null">Alle afstanden</option>
                        <option value="10">&lt; 10 km</option>
                        <option value="20">&lt; 20 km</option>
                        <option value="30">&lt; 30 km</option>
                        <option value="40">&lt; 40 km</option>
                        <option value="50">&lt; 50 km</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <div class="form-group form-group-lg">
                <label for="distance">Zoeken op datum</label>

                <v-date-picker class='v-date-picker'
                               mode='range'
                               v-model='rangeValue'
                               show-caps
                               :show-day-popover=false
                               input-class="form-control"
                               :min-date='new Date()'
                               popover-direction="bottom"
                               :input-props='{placeholder: "Selecteer datum", readonly: true}'
                               @change="sendRange"
                               placeholder=""
                               locale="nl-NL">
                </v-date-picker>
            </div>
        </div>

        <div class="col-xl-3">
            <div class="form-group form-group-lg">
                <label for="profession">Wat voor werk zoek je?</label>
                <select class="form-control" id="profession" v-model="filters.profession_id" :placeholder="$t('Beroep')">
                    <option :value="null">{{ $t('Toon mij alle beroepen') }}</option>
                    <slot name="professionOptions"></slot>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
    import DatePicker from 'vue2-datepicker'
    import Vue from 'vue'
    import VCalendar from 'v-calendar'

    Vue.use(VCalendar, {
        formats: {
            title: 'MMMM YYYY',
            weekdays: 'W',
            navMonths: 'MMM',
            input: ['YYYY-MM-DD', 'YYYY/MM/DD'],
            dayPopover: 'L',
        },
        locales: {
            'nl-NL': {
                firstDayOfWeek: 1,
                masks: {
                    L: 'DD-MM-YYYY',
                    // ...optional `title`, `weekdays`, `navMonths`, etc
                }
            }
        }
    });

    export default {
        components: {
            DatePicker,
            componentPrefix: 'vc',
        },
        props: {
            range: {
                type: Object,
            },
        },

        data () {
            return {
                query: null,
                distance: null,
                location: {
                    latitude: null,
                    longitude: null
                },
                filters: {
                    location: {
                        distance: null,
                        latitude: null,
                        longitude: null
                    },
                    company_name: null,
                    profession_id: null,
                    date_range: null
                },
                date: null,
                date_range: null,
                current_date: new Date(),
                selectedDate: {
                    start: new Date(),
                    end: new Date()
                },
                rangeValue: this.range
            }
        },
        methods: {
            queryChange: _.debounce(function () {
                this.$set(this.filters, 'company_name', this.query);
            }, 500),

            sendRange: _.debounce(function () {
                this.$set(this.filters, 'date_range', this.date_range);
                this.$refs.dateRange.popupVisible = false;
            }, 500),


            setLocation () {
                this.filters.location = Object.assign({}, this.location, {distance: this.distance});
            },

            setDateRange () {
                this.$set(this.filters, 'date_range', this.date_range);
                this.filters.location = Object.assign({}, this.location, {distance: this.distance});
            },
        },
        watch: {
            filters: {
                handler () {
                    this.$emit('change', this.filters)
                },
                deep: true
            },
            location: {
                handler () {
                    if (this.location.latitude === null && this.filters.location.latitude !== null) {
                        this.distance = null;
                        this.setLocation();
                        return;
                    }

                    if (this.distance > 0) {
                        this.setLocation();
                    }
                },
                deep: true
            },
            distance () {
                if (! this.location.latitude) {
                    return;
                }

                this.setLocation();
            },

            rangeValue() {
                this.$set(this.filters, 'date_range', this.rangeValue);
            }
        }
    }
</script>