<template>
    <form method="POST" :action="url" @submit.prevent>
        <slot></slot>
        <div id="accordion" class="mb-3">
            <div class="card" v-for="shift in shifts">
                <div class="card-header cursor-pointer row justify-content-between" :class="getColor(shift)" @click="() => click(shift)">
                    <div class="col-sm-12 col-md-6 d-flex">
                        <div class="icon mr-1">
                            <i class="fas" :class="getIcon(shift)"></i>
                        </div>
                        <div class="calendar-icon mr-1">
                            <i class="fas fa-calendar"></i>
                        </div>
                        <div class="calendar-day">
                            <strong>{{ shift.starts_at | moment('dd D MMMM | H:mm') }}</strong> - {{ shift.ends_at |
                            moment('H:mm') }}
                        </div>
                    </div>
                    <div class="col d-flex align-items-end">
                        <div class="calendar-icon mr-2">
                            <i class="fas fa-euro-sign"></i>
                        </div>
                        <div class="calendar-fee mr-3">
                            <div class="fee ml-1">
                                {{ shift.hourly_rate | currency('', ',', 2).replace(',00', ',-') }} {{ $t('euro per uur') }}
                            </div>
                        </div>
                        <div class="calendar-icon mr-2">
                            <i class="fas fa-user"></i>
                        </div>
                        <div class="calendar-reactions">
                            <div class="reactions ml-1">
                                {{ $t('Nog') }} {{ shift.spots_available ? shift.spots_available : 0 }} {{
                                $t('posities') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="'collapse ' + (shift === current ? 'show' : '')">
                    <div class="card-body p-4">
                        {{ shift.street }} {{ shift.house_number }}<br>
                        {{ shift.postal_code }}, {{ shift.city }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.score >= 100 && current">
            <a :href="url + '#start'" v-if="! userShift(current) && current.spots_available >= 1"
               class="btn btn-orange btn-lg w-100">{{ $t('Reageren') }}</a>
            <confirm-submit v-if="userShift(current) && ! isShiftCanceled(current)"
                            text="Weet je het zeker dat je wilt uitschrijven voor deze shift."
                            class="btn btn-gray btn-lg w-100" type="submit">{{ $t('Annuleren') }}
            </confirm-submit>
        </div>
        <div v-else>
            Helaas kun je nog niet reageren op een shift omdat je profiel niet compleet is. Klik <a target="_blank"
                                                                                                    :href="this.editUserUrl">hier</a>
            om je profiel compleet te maken.
        </div>
    </form>
</template>
<script>
    const STATUS_OPEN = 1;
    const STATUS_APPROVED = 2;
    const STATUS_DENIED = 3;
    const STATUS_USER_CANCELLED = 11;
    const STATUS_COMPANY_CANCELLED = 12;
    const STATUS_COMPANY_DELETED_USER = 13;

    export default {
        props: {
            shifts: {
                type: Array,
            },
            company: {
                type: Object
            },
            reactions: {
                type: Object
            },
            assignUrl: {
                type: String
            },
            detachUrl: {
                type: String
            },
            applyShift: {
                type: Number
            },
            editUserUrl: {},
            score: {}
        },

        data() {
            return {
                current: null
            }
        },

        mounted() {
            let shift;

            if (this.applyShift) {
                shift = _.find(this.shifts, {id: this.applyShift});
            }
            this.current = shift ? shift : this.shifts[0];

            if (shift && !this.userShift(shift)) {
                this.$refs['subscribeButton'].$el.dispatchEvent(new Event('click'))
            }
        },

        methods: {
            click(shift) {
                this.current = shift;
            },
            userShift(shift) {
                return this.reactions[shift.id];
            },
            isShiftCanceled(shift) {
                let status = this.userShift(shift);

                if (!status || status === 1 || status === 2) {
                    return false;
                }

                return [
                    STATUS_COMPANY_CANCELLED, STATUS_USER_CANCELLED, STATUS_COMPANY_DELETED_USER, STATUS_DENIED
                ].indexOf(status)
            },
            getIcon(shift) {
                let status = this.userShift(shift);

                if (status === STATUS_OPEN) {
                    return 'fa-clock';
                }

                if (status === STATUS_APPROVED) {
                    return 'fa-check';
                }

                if (this.isShiftCanceled(shift)) {
                    return 'fa-times';
                }

                return 'fa-plus-circle';
            },
            getColor(shift) {
                let status = this.userShift(shift);

                if (status === STATUS_OPEN) {
                    return 'status-open';
                }

                if (status === STATUS_APPROVED) {
                    return 'status-approved';
                }

                if (this.isShiftCanceled(shift)) {
                    return 'status-denied';
                }
            }
        },

        computed: {
            url() {
                if (!this.current) {
                    return;
                }

                let url = this.userShift(this.current) ? this.detachUrl : this.assignUrl;
                return url.replace('%ID%', this.current.id);
            }
        }
    }
</script>