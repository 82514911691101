<template>
    <div></div>
</template>
<script>
    import _ from 'lodash';

    export default {
        props: {
            messages: {
                type: Array
            },
            type: {
                default: 'info',
                type: String
            }
        },
        mounted () {
            console.log(1, this.messages);
            _.each(this.messages, (message) => {
                this.$notify({
                    group: 'default',
                    text: message.charAt(0).toUpperCase() + message.slice(1),
                    type: this.type,
                    duration: 10000,
                });
            });
        }
    }
</script>