<template>
    <div>
        <div class="row">
            <div class="col">
                <h5 class="card-title">{{ $t('Opleidingen en cursussen') }}</h5>
            </div>
            <div class="col text-right">
                <a href="#" @click.prevent="add">
                    <i class="education-icon far fa-plus-square fa-lg"></i>
                </a>
            </div>
        </div>

        <div v-for="(education, key) in all" :id="'education-' + key"
             class="d-flex profile-education flex-column border-top border-bottom py-3 w-100">
            <div class="row">
                <div class="col-12">
                    <a href="#" @click.prevent="remove(key)" class="float-right mb-2">
                        <i class="remove-educations far fa-trash"></i>
                    </a>
                </div>
                <div class="col-12">
                    <input v-if="education.id" type="hidden" :name="name + '['+ key +'][id]'" :value="education.id">

                    <div class="form-group row required">
                        <label :for="'title' + key" class="col-sm-4 col-form-label">{{ $t('Naam') }}</label>
                        <div class="col">
                            <input type="text" class="form-control" :id="'title' + key"
                                   :name="name + '['+ key +'][title]'"
                                   :placeholder="$t('Opleiding / cursus')"
                                   v-model="education.title">
                        </div>
                    </div>
                    <div class="form-group row required">
                        <label :for="'started_at' + key" class="col-sm-4 col-form-label">{{ $t('Begonnen op') }}</label>
                        <div class="col">
                            <year-picker v-model="education.started_at"
                                         :id="'started_at' + key"
                                         :name="name + '['+ key +'][started_at]'" :placeholder="$t('Begonnen')">
                            </year-picker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label :for="'stopped_at' + key" class="col-sm-4 col-form-label">{{ $t('Afgerond op') }}</label>
                        <div class="col">
                            <year-picker
                                    v-if="education.started_at"
                                    v-model="education.stopped_at"
                                    :name="name + '['+ key +'][stopped_at]'" :placeholder="$t('Afgerond')">
                                <option :value="null">Nog niet afgerond</option>
                            </year-picker>
                        </div>
                    </div>
                    <div class="form-group row" v-if="education.stopped_at">
                        <div class="col-sm-4"></div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input"
                                       type="checkbox"
                                       v-model="education.succeeded"
                                       :value="1"
                                       :name="name + '['+ key +'][succeeded]'"
                                       :id="'succeeded' + key">
                                <label class="form-check-label" :for="'succeeded' + key">
                                    {{ $t('Diploma of certificaat behaald')}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import YearPicker from './YearPicker';

    export default {
        components: {
            YearPicker
        },
        props: {
            educations: {
                type: Array
            },
            name: {
                type: String
            }
        },
        data() {
            return {
                education: {
                    started_at: new Date().getFullYear()
                },
                all: this.educations ? this.educations : [],
                flatpickr: {
                    enableTime: false,
                    altFormat: 'd-m-Y',
                    altInput: true,
                    allowInput: true,
                    dateFormat: 'Y-m-d'
                }
            }
        },
        methods: {
            add() {
                this.all.push(Object.assign({}, this.education));
                setTimeout(() => window.location.hash = '#education-' + (this.all.length - 1), 100);
            },
            remove(key) {
                this.$confirm(this.$t('Weet je het zeker?'), this.$t('Verwijderen')).then(() => {
                    this.$delete(this.all, key);
                });
            }
        }
    }
</script>