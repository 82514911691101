<template>
    <vue-dropzone :options='dropzoneOptions' id='dropzone'></vue-dropzone>
</template>
<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'app',
        props: {
            url: {},
            name: {},
            profilePicture: {},
            max: {
                default: 1
            }
        },
        components: {
            vueDropzone: vue2Dropzone
        },
        data () {
            return {
                dropzoneOptions: {
                    method: 'post',
                    paramName: this.name,
                    url: this.url,
                    uploadMultiple: false,
                    acceptedFiles: '.jpg,.jpeg,.png,.gif,.pdf',
                    maxFilesize: 5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: {
                        'X-CSRF-TOKEN': window.csrfToken
                    },
                    init: function() {
                        this.on('sending', function(file, xhr, formData){
                            formData.append('_method', 'PUT');
                        });
                        this.on('addedfile', function() {
                            if (this.files.length > this.max){
                                this.removeFile(this.files[0]);
                            }
                        });
                        this.on("error", function(file, response) {
                            $('.alert-back-identification').html(response);
                            $('.alert-back-identification').removeClass('d-none');
                        });
                        this.on("success", function(file, response) {
                            $('.alert-back-identification').addClass('d-none');
                            $('button[type="submit"]').attr('disabled', false);
                        });
                        this.on("removedfile", function(file, response) {
                            $('.alert-back-identification').addClass('d-none');
                            $('button[type="submit"]').attr('disabled', true);
                        });
                    },


                    dictDefaultMessage: "Sleep je bestanden hier of klik om te uploaden",
                    dictFallbackMessage: this.$t('dropzone.dictFallbackMessage'),
                    dictFileTooBig: this.$t('dropzone.dictFileTooBig'),
                    dictInvalidFileType: this.$t('dropzone.dictInvalidFileType'),
                    dictCancelUpload: this.$t('dropzone.dictCancelUpload'),
                    dictUploadCanceled: this.$t('dropzone.dictUploadCanceled'),
                    dictCancelUploadConfirmation: this.$t('dropzone.dictCancelUploadConfirmation'),
                    dictRemoveFile: this.$t('dropzone.dictRemoveFile'),
                    dictRemoveFileConfirmation: this.$t('dropzone.dictRemoveFileConfirmation'),
                    dictMaxFilesExceeded: this.$t('dropzone.dictMaxFilesExceeded'),
                }
            }
        },
        methods: {
            afterComplete(file) {
                console.log(file);
            }
        }
    }
</script>