$('[data-submit-on-change]').find('select, input').on('change', function (element) {
    let $element = $(this);
    $element.parents('form').submit();
});

$('[ajax-submit-on-any-change]').find('select, input, textarea').on('change', function (element) {

    let $form = $(this).parents('form');

    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize()
    });
});

$('[data-hide-when-checked]').each(function () {
    let $checkbox = $(this);
    let $hide = $($checkbox.data('hide-when-checked'));

    $checkbox.on('init change', () => {
        if ($checkbox.is(':checked')) {
            $hide.hide();
            $hide.find('input').attr('disabled', 'disabled');
            return;
        }

        $hide.show();
        $hide.find('input').removeAttr('disabled');
    });

    $checkbox.trigger('init');
});

$('[data-show-when-checked]').each(function () {
    let $checkbox = $(this);
    let $hide = $($checkbox.data('show-when-checked'));

    $checkbox.on('init change', () => {
        if ($checkbox.is(':checked')) {
            $hide.show();
            $hide.find('input').removeAttr('disabled');
            return;
        }

        $hide.hide();
        $hide.find('input').attr('disabled', 'disabled');
    });

    $checkbox.trigger('init');
});

$('form[method=POST]').on('submit', function () {
    let $form = $(this);

    $submits = $form.find('[type=submit]').prop('disabled', true);

    setTimeout(() => $submits.prop('disabled', false), 2000);
});