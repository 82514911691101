window.actionEvents = {
    'click .action-show': function(e, value, row, index) {
        e.preventDefault();
        let table_id = this.$el_.attr('id');
        let actions = tables[table_id].actions;

        if (typeof row.show_url !== "undefined") {
            window.location.href = row.show_url;
        } else if (typeof actions.show.route !== "undefined") {
            window.location.href = route(actions.show.route, {
                id: row.id
            });
        } else if (typeof actions.show.url !== "undefined") {
            window.location.href = actions.show.url+'/'+row.id;
            // window.location.href = sprintf(actions.show.url, row.id);
        }
    },
    'click .action-edit': function(e, value, row, index) {
        e.preventDefault();
        let table_id = this.$el_.attr('id');
        let actions = tables[table_id].actions;
        if (typeof row.edit_url !== "undefined") {
            window.location.href = row.edit_url;
        } else if (typeof actions.edit.route !== "undefined") {
            // Ziggy Routes
            let params = {};
            if (typeof actions.edit.params !== "undefined") {
                $.each(actions.edit.params, function(k, v) {
                    if (v.includes('[')) {
                        let matches = v.match(/\[(.*?)\]/);
                        let m = matches[1];
                        params[k] = row[m];
                    } else {
                        params[k] = v;
                    }
                });
            } else {
                params = {
                    id: row.id
                };
            }
            window.location.href = route(actions.edit.route, params);
        } else if (typeof actions.edit.url !== "undefined") {
            window.location.href = actions.edit.url+'/'+row.id;
            // window.location.href = sprintf(actions.edit.url, row.id);
        }
    },
    'click .action-destroy': function(e, value, row, index) {
        e.preventDefault();
        let _token = $("input[name=_token]").val();
        let id = this.data[index].id;
        let table_id = this.$el_.attr('id');
        let actions = tables[table_id].actions;
        bootbox.confirm({
            title: actions.destroy.title,
            message: actions.destroy.message,
            buttons: {
                cancel: {
                    label: 'No',
                    className: 'btn-outline-primary'
                },
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                }
            },
            callback: function (result) {
                if (result === false) {
                    return;
                }
                let url = '';
                if (row.delete_url) {
                    url = row.delete_url;
                } else if (typeof actions.destroy.route !== "undefined") {
                    // Ziggy Routes
                    let params = {};
                    if (typeof actions.destroy.params !== "undefined") {
                        $.each(actions.destroy.params, function (k, v) {
                            if (v.includes('[')) {
                                let matches = v.match(/\[(.*?)\]/);
                                let m = matches[1];
                                params[k] = row[m];
                            } else {
                                params[k] = v;
                            }
                        });
                    } else {
                        params = {
                            id: row.id
                        };
                    }
                    url = route(actions.destroy.route, params);
                } else if (typeof actions.destroy.url !== "undefined") {
                    url = actions.destroy.url + '/' + id;
                }
                return $.ajax({
                    url: url,
                    type: 'POST',
                    data: {
                        '_method': 'DELETE',
                        '_token': _token,
                    },
                    success: function (response) {
                        switch (response.status) {
                            case 'ERROR':
                                Swal.fire({
                                    icon: 'error',
                                    title: response.message,
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'bg-info'
                                    }
                                });
                                break;
                            case 'OK':
                                $('#' + table_id).bootstrapTable('refresh');
                                Swal.fire({
                                    icon: 'success',
                                    title: response.message,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                                break;
                        }
                    },
                    error: function (xhr) {
                        switch (parseInt(xhr.status, 10)) {
                            case 500:
                                Swal.fire({
                                    icon: "error",
                                    title: typeof xhr.responseJSON === "undefined" ? __('An error has occurred.') : xhr.responseJSON.message,
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: "bg-info"
                                    }
                                });
                                break;
                        }
                    }
                });
            }
        });
    },
    'click .action-duplicate': function(e, value, row, index) {
        e.preventDefault();
        let _token = $('input[name=_token]').val();
        let id = this.data[index].id;
        let table_id = this.$el_.attr('id');
        let actions = tables[table_id].actions;
        bootbox.confirm({
            title: actions.duplicate.title,
            message: actions.duplicate.message,
            buttons: {
                cancel: {
                    label: 'Nee',
                    className: 'btn-outline-primary'
                },
                confirm: {
                    label: 'Ja',
                    className: 'btn-success'
                }
            },
            callback: function (result) {
                if (result === false) {
                    return;
                }
                let url = '';
                if (typeof actions.duplicate.route !== "undefined") {
                    url = route(actions.duplicate.route, {
                        id: row.id
                    });
                } else if (typeof actions.duplicate.url !== "undefined") {
                    url = actions.duplicate.url + '/' + id;
                }
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: {
                        '_token': _token,
                    },
                    success: function (response) {
                        switch (response.status) {
                            case 'ERROR':
                                Swal.fire({
                                    icon: 'error',
                                    title: response.message,
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'bg-info'
                                    }
                                });
                                break;
                            case 'OK':
                                $('#'+table_id).bootstrapTable('refresh');
                                Swal.fire({
                                    icon: 'success',
                                    title: response.message,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                                break;
                        }
                    },
                    error: function (xhr) {
                        Swal.fire({
                            icon: "error",
                            title: xhr.responseJSON.message,
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: "bg-info"
                            }
                        });
                    }
                });
            }
        });
        $('.modal-header').addClass('bg-danger text-white');
    }
};