<template>
    <div>
        <div class="row">
            <div class="col">
                <h5 class="card-title">{{ $t('Mijn ervaring') }}</h5>
            </div>
            <div class="col text-right">
                <a href="#" @click.prevent="add">
                    <i class="experiences-icon far fa-plus-square fa-lg"></i>
                </a>
            </div>
        </div>

        <div v-for="(experience, key) in all" :id="'experiences-' + key" class="d-flex profile-experience flex-column border-top border-bottom py-3 w-100">
            <div class="row">
                <div class="col-12">
                    <a href="#" @click.prevent="remove(key)" class="float-right  mb-2">
                        <i class="remove-experiences far fa-trash"></i>
                    </a>
                </div>
                <div class="col-12">
                    <input v-if="experience.id" type="hidden" :name="name + '['+ key +'][id]'" :value="experience.id">
                    <div class="form-group row required">
                        <label :for="'profession' + key" class="col-sm-4 col-form-label">{{ $t('Beroep') }}</label>
                        <div class="col">
                            <select :name="name + '[' + key + '][profession_id]'" class="form-control" v-model="experience.profession_id">
                                <option :value="null">
                                    Maak een keuze
                                </option>
                                <option v-for="professionItem in professions"
                                        :value="professionItem.id">{{professionItem.title}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row required">
                        <label :for="'company_name' + key" class="col-sm-4 col-form-label">{{ $t('Bedrijfsnaam') }}</label>
                        <div class="col">
                            <input type="text" class="form-control" :id="'company_name' + key" :name="name + '['+ key +'][company_name]'"
                                   :placeholder="$t('Bedrijfsnaam')"
                                   v-model="experience.company_name">
                        </div>
                    </div>

                    <div class="form-group row required">
                        <label :for="'started_at' + key" class="col-sm-4 col-form-label">{{ $t('Begonnen op') }}</label>
                        <div class="col">
                            <year-picker v-model="experience.started_at"
                                         :id="'started_at' + key"
                                         :name="name + '['+ key +'][started_at]'" :placeholder="$t('Begonnen')"></year-picker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4"></div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="experience.current_job" :id="'current_job' + key">
                                <label class="form-check-label" :for="'current_job' + key">
                                    {{ $t('Dit is mijn huidige baan') }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <input v-if="experience.current_job" type="hidden" :name="name + '['+ key +'][stopped_at]'" :value="null"/>
                    <div class="form-group row" v-if="! experience.current_job">
                        <label :for="'stopped_at' + key" class="col-sm-4 col-form-label">{{ $t('Afgerond op') }}</label>
                        <div class="col">
                            <year-picker v-model="experience.stopped_at"
                                        v-if="! experience.current_job"
                                        :id="'ended_at' + key"
                                        :start="experience.started_at"
                                        :name="name + '['+ key +'][stopped_at]'" :placeholder="$t('Afgerond')">
                            </year-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import YearPicker from './YearPicker';

    export default {
        components: {
            YearPicker
        },
        props: {
            experiences: {
                type: Array,
                default: () => []
            },
            professions: {
                type: Array,
                default: () => []
            },
            profession_skills: {
                type: Array,
                default: () => []
            },
            name: {
                type: String
            }
        },
        data () {
            return {
                experience: {
                    profession_id: null
                },
                all: this.experiences,
                flatpickr: {
                    enableTime: false,
                    altFormat: 'd-m-Y',
                    altInput: true,
                    allowInput: true,
                    dateFormat: 'Y-m-d'
                }
            }
        },
        methods: {
            add () {
                this.all.push(Object.assign({}, this.experience));
                setTimeout(() => window.location.hash = '#experiences-' + (this.all.length-1), 100);
            },
            remove (key) {
                this.$confirm(this.$t('Weet je het zeker?'), this.$t('Verwijderen')).then(() => {
                    this.$delete(this.all, key);
                });
            }
        }
    }
</script>