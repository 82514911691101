/**
 * Tab.
 * @param {string} url
 * @param {string} id
 */
window.tab = function(url, id) {
    $.ajax({
        url: url,
        type: "GET",
        success: function(data) {
            $('a[data-id="'+id+'"]').tab("show");
            $("#"+id).empty().html(data);
            if (tabs.hasOwnProperty(id) === false) {
                return false;
            }
            if (tabs[id].tables) {
                if (typeof tables !== "undefined") {
                    let mergeObjects = $.extend({}, tables, tabs[id].tables);
                    tables = mergeObjects;
                    initBootstrapTables();
                } else {
                    tables = tabs[id].tables;
                    initBootstrapTables();
                }
            }
            if (typeof tabs[id].callback !== typeof undefined) {
                let fn = window[tabs[id].callback];
                if (typeof fn === "function") {
                    fn();
                }
            }
        }
    });
};

/**
 * Init Tabs.
 * @returns {Boolean}
 */
window.initTabs = function() {
    let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    $('a[data-toggle="tab"]').on("click", function(e) {
        let url = $(this).attr("data-url");
        let id = $(this).attr("data-id");
        if (isChrome) {
            window.location.hash = "";
        } else if (!$(this).attr("data-dont-toggle-to-hash")) {
            window.location.hash = "#"+id;
        }
        if (url) {
            tab(url, id);
        }
    });
    if (typeof tabs !== "undefined") {
        let id = '';
        if (window.location.hash) {
            id = window.location.hash.split("#")[1];
        } else if (tabs.hasOwnProperty('default') !== false) {
            id = tabs.default.id;
        } else {
            return false;
        }
        let url = $('a[data-id="'+id+'"]').attr("data-url");
        if (url) {
            tab(url, id);
        } else {
            $('a[data-id="'+id+'"]').tab("show");
        }
    }
};
