<template>
    <div>
        <div class="holder">
            <h2 class="holder-title">Zoek direct een horeca onderneming</h2>
            <div class="d-flex holder-row-top mb-4 filters-holder border-bottom pb-4">
                <div class="row w-100">
                    <div class="col-lg-9">
                        <input type="text" class="form-control" placeholder="Voer de naam in van de horeca onderneming die u zoekt" id="name" v-model="query" @input="queryChange">
                    </div>
                    <div class="col-lg-3">
                        <select v-model="filters.checked" @change="fetch" class="form-control">
                            <option value="0">Nog te controleren</option>
                            <option value="1">Gecontroleerd</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="text-center pb-2">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div class="row" v-if="!loading && results.length > 0">
            <div class="col-sm-6 col-lg-6 col-xl-3" v-for="result in results">
                <div class="card mb-4">
                    <img class="card-img-top" :src="result.profile_picture" :alt="result.name">
                    <div class="card-body">
                        <h5 class="card-title"><a :href="result.url_edit">{{ result.name }}</a></h5>
                        <div class="card-list-info">
                            <div class="d-flex">
                                <div class="icon mr-2">
                                    <i class="far fa-map-marker"></i>
                                </div>
                                <div class="text">{{ result.street }} {{ result.house_number }}, {{ result.postal_code }} {{ result.city }}</div>
                            </div>
                            <div class="d-flex">
                                <div class="icon mr-2">
                                    <i class="far fa-phone"></i>
                                </div>
                                <div class="text">{{ result.phone_number }}</div>
                            </div>
                            <div class="d-flex">
                                <div class="icon mr-2">
                                    <i class="fa fa-exclamation"></i>
                                </div>
                                <div class="text">{{ result.total_open_issues }} openstaande ticket(s)</div>
                            </div>
                            <div class="d-flex">
                                <div class="icon mr-2">
                                    <i class="fa fa-briefcase"></i>
                                </div>
                                <div class="text">{{ result.total_shifts }} uitstaande shifts</div>
                            </div>
                            <div class="d-flex">
                                <div class="icon mr-2">
                                    <i class="far fa-watch"></i>
                                </div>
                                <div class="text">{{ result.total_open_shifts }} openstaande reacties</div>
                            </div>
                            <div class="d-flex">
                                <div class="icon mr-2">
                                    <i class="far fa-check"></i>
                                </div>
                                <div class="text">{{ result.totel_accepted_shifts }} aangenomen personeel</div>
                            </div>
                            <div v-if="!result.checked">
                                <form method="POST" :action="result.approve_url">
                                    <input type="hidden" name="_method" value="PUT">
                                    <input type="hidden" name="_token" :value="csrf">
                                    <confirm-submit
                                            ref="subscribeButton"
                                            text="Weet je zeker dat je dit bedrijf wilt accorderen?"
                                            class="btn btn-orange btn-lg w-100 mt-4"
                                            type="submit">
                                        {{ $t('Accepteren') }}
                                    </confirm-submit>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default {
        data () {
            return {
                query: null,
                filters: {
                    name: null,
                    checked: 0,
                },
                sortBy: 'created_at',
                results: null,
                loading: true,
                pagination: {
                    links: {},
                    meta: {}
                },
                csrf: window.csrfToken,
            }
        },
        watch: {
            filters: {
                handler () {
                    this.$emit('change', this.filters)
                },
                deep: true
            }
        },
        mounted () {
            this.fetch();
        },
        methods: {
            queryChange: _.debounce(function () {
                this.$set(this.filters, 'name', this.query);
                this.setFilters(this.filters);
            }, 500),
            setFilters(filters)
            {
                this.filters = Object.assign(this.filters, filters);
                this.fetch();
            },
            fetch()
            {
                console.log(this.filters);
                this.startLoading();
                return window.axios.get('/company-search', {
                    params: {
                        'sortBy': this.sortBy,
                        'filters': _.omitBy(this.filters, _.isNil)
                    }
                }).then((resp) => {
                    this.stopLoading();
                    this.pagination.links = resp.data.links;
                    this.pagination.meta = resp.data.meta;
                    this.results = resp.data.data;
                })
                .catch(this.stopLoading);
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading()
            {
                this.loading = false;
            }
        }
    }
</script>