<template>
    <input type="text"
           v-model="query"
           :placeholder="$t('Uw postcode')"
           :class="$attrs['class']"
           :id="$attrs['id']"
           @input="change"
    >
</template>
<script>
    export default {
        props: ['value'],
        data () {
            return {
                query: '',
                latitude: null,
                longitude: null
            }
        },
        methods: {
            change: _.debounce(function () {
                this.fetch();
            }, 500),

            set (latitude, longitude) {
                this.latitude = latitude;
                this.longitude = longitude;

                this.$emit('input', {
                    latitude: this.latitude,
                    longitude: this.longitude
                });
            },

            fetch () {
                if (this.query === '') {
                    this.set(null, null);
                    return;
                }

                this.$geocoder.send({
                    zip_code: this.query + ', NL'
                }, response => {
                    if (response.status !== 'OK') {
                        this.set(null, null);
                        return;
                    }

                    this.set(
                        response.results[0].geometry.location.lat,
                        response.results[0].geometry.location.lng
                    );
                });
            }
        }
    }
</script>