<template>
    <select @input="$emit('input', $event.target.value)" :value="selected" class="form-control" :name="name">
        <slot></slot>
        <option v-for="year in years">{{ year }}</option>
    </select>
</template>
<script>
    export default {
        props: {
            name: {},
            start: {},
            value: {}
        },
        computed: {
            years() {
                let now = (new Date()).getFullYear();

                let years = [];

                let start = (new Date()).getFullYear() - 60;

                if (this.start) {
                    start = this.start.toString().split('-');
                    start = start[0];
                }

                console.log(start);

                let i;
                for (i = now; i >= start; i--) {
                    years.push(i);
                }

                return years;
            },
            selected() {
                if (! this.value) {
                    return (new Date()).getFullYear();
                }

                return this.value.toString().split('-')[0];
            }
        }
    }
</script>