<template>
    <div>
        <div class="holder holder-seperator">
            <h2 class="holder-title"></h2>
            <input type="text" class="form-control w-100 mb-4" placeholder="Zoek freelancers op naam" id="name" v-model="query" @input="queryChange">
            <div v-if="loading" class="text-center">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
            <div v-if="!loading && results && results.length > 0">
                <div class="row">
                    <div class="col-sm-6 col-lg-6 col-xl-3 mb-4" v-for="result in results">
                        <div class="card mb-4">
                            <img class="card-img-top" :src="result.profile_picture" :alt="result.name">
                            <div class="card-img-overlay">
                                <div class="float-right">
                                    <form v-if=!result.favorite method="POST" :action="route">
                                        <input type="hidden" name="company_id" :value="company_id">
                                        <input type="hidden" name="user_id" :value="result.id">
                                        <input type="hidden" name="_method" value="POST">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <input type="hidden" name="profession_id" :value="profession_id">
                                        <input type="hidden" name="company_profession_id" :value="company_profession_id">
                                        <confirm-submit class="btn btn-link p-0 ml-2" :text="`Weet je zeker dat je ` + result.name + ` wilt toevoegen aan je favorieten?`"><i class="far fa-heart fa-lg"></i></confirm-submit>
                                    </form>

                                    <form v-if=result.favorite method="POST" :action="result.destroy_favorite_route">
                                        <input type="hidden" name="_method" value="DELETE">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <confirm-submit class="btn btn-link p-0 ml-2" :text="`Weet je zeker dat je ` + result.name + ` wilt verwijderen uit je favorieten?`"><i class="fas fa-heart fa-lg"></i></confirm-submit>
                                    </form>
                                </div>
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">{{ result.name }}</h5>
                                <div class="card-list-info">
                                    <div class="d-flex">
                                        <div class="icon mr-2">
                                            <i class="far fa-map-marker"></i>
                                        </div>
                                        <div class="text">{{ result.city }}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="icon mr-2">
                                            <i class="far fa-male"></i>
                                        </div>
                                        <div class="text">Man 24 jaar (Data hebben we niet)</div>
                                    </div>
                                </div>
                                <a :href="`mailto:${result.email}`" class="btn btn-orange btn-lg mt-3 w-100">
                                    <span class="text">
                                        Stuur email
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!loading && results.length == 0">
                <div class="card">
                    <div class="card-header">
                        <h3>Oeps</h3>
                    </div>
                    <div class="card-body">
                        Je kunt alleen favorieten toevoegen die voor je gewerkt hebben.
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        props: [
            'route',
            'company_id',
            'profession_id',
            'company_profession_id'
        ],
        data () {
            return {
                query: null,
                filters: {
                    profession: this.profession_id,
                    name: null,
                    company_id: this.company_id
                },
                sortBy: 'created_at',
                results: null,
                loading: true,
                userId: null,
                destroyRoute: null,
                pagination: {
                    links: {},
                    meta: {}
                },
                csrf: window.csrfToken,
            }
        },
        watch: {
            filters: {
                handler () {
                    this.$emit('change', this.filters)
                },
                deep: true
            }
        },
        mounted () {
            this.fetch();
        },
        methods: {
            addFavorite (result) {
                this.userId = result.id;
                this.$modal.show('add-favorite');
            },
            destroyFavorite (result) {
                this.userId = result.id;
                this.destroyRoute = this.route + '/' + this.userId;
                this.$modal.show('destroy-favorite');
            },
            hideFavoriteModal () {
                this.$modal.hide('add-favorite');
                this.$modal.hide('destroy-favorite');
            },
            queryChange:function () {
                if (this.query.length > 0) {
                    this.$set(this.filters, 'name', this.query);
                    this.setFilters(this.filters);
                }

                this.result = this.favorites
            },
            setFilters(filters)
            {
                this.filters = Object.assign(this.filters, filters);
                this.fetch();
            },
            fetch()
            {
                this.startLoading();
                return window.axios.get('/user-search', {
                    params: {
                        'sortBy': this.sortBy,
                        'company_id': this.company_id,
                        'company_profession_id': this.company_profession_id,
                        'profession_id': this.profession_id,
                        'filters': _.omitBy(this.filters, _.isNil)
                    }
                }).then((resp) => {
                    this.stopLoading();
                    this.pagination.links = resp.data.links;
                    this.pagination.meta = resp.data.meta;
                    this.results = resp.data.data;
                })
                    .catch(this.stopLoading);
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading()
            {
                this.loading = false;
            }
        }
    }
</script>