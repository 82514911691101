<template>
    <div class="holder">
        <div class="">
            <filters @change="setFilters">
                <template slot="professionOptions">
                    <slot name="professionOptions"></slot>
                </template>
            </filters>
        </div>
        <div class="d-flex holder-row-top mb-4 border-top border-bottom py-2 filters-holder">
            <div class="total-results mr-5">
                <span v-if="loading">
                    <i class="fas fa-spinner fa-spin"></i>
                </span>
                {{ loading ? '' : this.pagination.meta.total }} resultaten
            </div>
            <div class="sort-by-holder d-flex">
                <span class="sort-by-label mr-2">Sorteren op</span>
                <select v-model="sortBy" @change="fetch" id="" class="sort-by-select">
                    <option value="created_at">Datum</option>
                    <option value="hourly_rate">Tarief</option>
                </select>
            </div>
        </div>
        <div v-if="loading" class="text-center">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div class="row" v-infinite-scroll="loadMore" infinite-scroll-distance="1000">
            <card v-for="(result, key) in results" :shift="result" :key="key"></card>
        </div>
    </div>
</template>
<script>
    import Filters from './Filters';
    import Card from './Card';
    import infiniteScroll from 'vue-infinite-scroll';

    export default {
        directives: {
            infiniteScroll
        },
        components: {
            Filters,
            Card
        },
        data () {
            return {
                filters: {},
                sortBy: 'starts_at',
                results: [],
                loading: true,
                pagination: {
                    links: {},
                    meta: {}
                }
            }
        },
        mounted () {
            this.fetch();
        },
        methods: {
            setFilters(filters)
            {
                this.filters = Object.assign(this.filters, filters);
                this.fetch();
            },
            fetch()
            {
                this.startLoading();
                return window.axios
                    .get(window.location.href, {
                        params: {
                            'sortBy': this.sortBy,
                            'filters': _.omitBy(this.filters, _.isNil)
                        }
                    })
                    .then(this.parseResponse)
                    .catch(this.stopLoading);
            },
            loadMore()
            {
                if (this.loading || ! this.pagination.links.next) {
                    return;
                }

                this.startLoading();

                return window.axios
                    .get(this.pagination.links.next)
                    .then((resp) => this.parseResponse(resp, true))
                    .catch(this.stopLoading);
            },
            parseResponse(resp, append) {
                this.stopLoading();
                this.pagination.links = resp.data.links;
                this.pagination.meta = resp.data.meta;

                if (append) {
                    this.results = this.results.concat(resp.data.data);
                    return;
                }

                this.results = resp.data.data;
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading()
            {
                this.loading = false;
            }
        }
    }
</script>