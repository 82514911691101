<template>
    <div>
        <div class="holder">
            <h2 class="holder-title">Zoek direct een Freelier</h2>
            <div class="d-flex holder-row-top mb-4 filters-holder border-bottom pb-4">
                <input type="text" class="form-control w-100" placeholder="Zoek freelancers op naam" id="name" v-model="query" @input="queryChange">

            </div>
            <div v-if="loading" class="text-center pb-2">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
        <div class="holder">
            <div class="row" v-if="!loading && results.length > 0">
                <div class="col-sm-6 col-lg-6 col-xl-3" v-for="result in results">
                    <div class="card mb-4">
                        <img class="card-img-top" :src="result.profile_picture" :alt="result.name">
                            <div class="card-body">
                            <h5 class="card-title"><a :href="result.direction_profile_route">{{ result.name }}</a></h5>
                            <div class="card-list-info">
                                <div class="d-flex">
                                    <div class="icon mr-2">
                                        <i class="far fa-exclamation"></i>
                                    </div>
                                    <div class="text">{{ result.total_open_issues }} openstaande ticket(s)</div>
                                </div>
                                <div class="d-flex">
                                    <div class="icon mr-2">
                                        <i class="far fa-watch"></i>
                                    </div>
                                    <div class="text">{{ result.total_open_shifts }} openstaande shifts</div>
                                </div>
                                <div class="d-flex">
                                    <div class="icon mr-2">
                                        <i class="far fa-check"></i>
                                    </div>
                                    <div class="text">{{ result.totel_accepted_shifts }} aangenomen shifts</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else-if="!loading && results.length == 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3>Oeps</h3>
                        </div>
                        <div class="card-body">
                            <p>Er zijn geen freelancers gevonden die voldoen aan de zoekcriteria</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
    export default {
        data () {
            return {
                query: null,
                filters: {
                    name: null,
                    role: 3
                },
                sortBy: 'created_at',
                results: null,
                loading: true,
                pagination: {
                    links: {},
                    meta: {}
                },
                csrf: window.csrfToken,
            }
        },
        watch: {
            filters: {
                handler () {
                    this.$emit('change', this.filters)
                },
                deep: true
            }
        },
        mounted () {
            this.fetch();
        },
        methods: {
            queryChange: _.debounce(function () {
                this.$set(this.filters, 'name', this.query);
                this.setFilters(this.filters);
            }, 500),
            setFilters(filters)
            {
                this.filters = Object.assign(this.filters, filters);
                this.fetch();
            },
            fetch()
            {
                this.startLoading();
                return window.axios.get('/user-search', {
                    params: {
                        'sortBy': this.sortBy,
                        'filters': _.omitBy(this.filters, _.isNil)
                    }
                }).then((resp) => {
                    this.stopLoading();
                    this.pagination.links = resp.data.links;
                    this.pagination.meta = resp.data.meta;
                    this.results = resp.data.data;
                })
                    .catch(this.stopLoading);
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading()
            {
                this.loading = false;
            }
        }
    }
</script>