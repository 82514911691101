var Bugsnag = require('@bugsnag/js');
var BugsnagPluginVue = require('@bugsnag/plugin-vue');

Bugsnag.start({
    apiKey: '4584819a83f808b310f5b42c91f3847b',
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV
});

import "@babel/polyfill";
import Vue from 'vue';
import Notifications from 'vue-notification';
import VueI18n from 'vue-i18n';
import nl_NL from '../../lang/nl_NL.json';
import vSelect from 'vue-select';
import VueCurrencyFilter from 'vue-currency-filter';
import * as VueGoogleMaps from 'vue2-google-maps';
import { Button, MessageBox, Message, Notification, Alert } from 'element-ui';
import element_nl_NL from 'element-ui/lib/locale/lang/nl';
import locale from 'element-ui/lib/locale';
import flatpickr from 'flatpickr';
import VueFlatPickr from 'vue-flatpickr-component';
import {Dutch} from 'flatpickr/dist/l10n/nl';
import VModal from 'vue-js-modal'
import PictureInput from 'vue-picture-input';
import Geocoder from "@pderas/vue2-geocoder";
require( 'datatables.net' );
require('select2');
require('croppie');

let mapsKey = 'AIzaSyA5DT4He-fKr01hYx0oFIMIGF5avwpqe8g';

/**
 * Trumbowyg (Wysiwyg Editor)
 */

import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
Vue.use(VueTrumbowyg);

/**
 * Geocoder
 */

Vue.use(Geocoder, {
    googleMapsApiKey: mapsKey
});

Vue.$geocoder.setDefaultCountryCode('NL');

/**
 * Flatpickr
 */

const dateTimeConfig = {
    enableTime: true,
    time_24hr: true,
    altInput: true,
    altFormat: "d-m-Y H:i",
    dateFormat: "Y-m-d H:i:S",
    defaultDate: new Date()
};

flatpickr.localize(Dutch);

Vue.use(VueFlatPickr);

/**
 * Element UI
 */
locale.use(element_nl_NL);

Vue.component(MessageBox.name, MessageBox);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;

/**
 * MomentJS
 */
const moment = require('moment')
require('moment/locale/nl');

Vue.use(require('vue-moment'), {
    moment
});

// Bootbox

import bootbox from 'bootbox';
window.bootbox = bootbox;

// Bootstrap Table

window.bootstrap = {
    Tooltip: {
        VERSION: "4"
    }
}

import('bootstrap-table');

// NProgress

import nprogress from 'nprogress';
window.NProgress = nprogress;

// SweetAlert2

import sweetalert2 from 'sweetalert2';
window.Swal = sweetalert2;

/**
 * Currency Filter
 */
Vue.use(VueCurrencyFilter,
    {
        symbol : '€',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true
    });


/**
 * Modal
 */
Vue.use(VModal);


/**
 * Google Maps
 */
Vue.use(VueGoogleMaps, {
    load: {
        key: mapsKey
    }
});

Vue.component('google-map', VueGoogleMaps.Map);
Vue.component('google-marker', VueGoogleMaps.Marker);

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(Notifications);
Vue.use(VueI18n);
Vue.component('v-select', vSelect);

import ExampleComponent from './components/ExampleComponent.vue';
import LaravelMessageBag from './components/LaravelMessageBag.vue';
import FreelyDropzone from './components/FreelyDropzone.vue';
import FreelyDropzone2 from './components/FreelyDropzone2.vue';
import ProfilePicDropzone from './components/ProfilePicDropzone.vue';
import KvkSearch from './components/KvkSearch.vue';
import ShiftSearch from './components/Search/ShiftSearch.vue';
import ConfirmSubmit from './components/ConfirmSubmit.vue';
import FavoriteUserSearch from './components/Search/FavoriteUserSearch.vue';
import ShiftSelector from './components/Shifts/ShiftSelector.vue';
import ExperiencesForm from './components/Forms/ExperiencesForm.vue';
import EducationsForm from './components/Forms/EducationsForm.vue';
import FavoriteProfessions from './components/Users/FavoriteProfessions.vue';
import PostalCodeInput from './components/Search/PostalcodeInput.vue';
import CompanySearch from './components/Search/CompanySearch.vue';
import UserSearch from './components/Search/UserSearch.vue';

Vue.component('example-component', ExampleComponent);
Vue.component('laravel-message-bag', LaravelMessageBag);
Vue.component('freely-dropzone', FreelyDropzone);
Vue.component('freely-dropzone-2', FreelyDropzone2);
Vue.component('profile-pic-dropzone', ProfilePicDropzone);
Vue.component('kvk-search', KvkSearch);
Vue.component('shift-search', ShiftSearch);
Vue.component('confirm-submit', ConfirmSubmit);
Vue.component('favorite-user-search', FavoriteUserSearch);
Vue.component('shift-selector', ShiftSelector);
Vue.component('experiences-form', ExperiencesForm);
Vue.component('educations-form', EducationsForm);
Vue.component('favorite-professions', FavoriteProfessions);
Vue.component('picture-input', PictureInput);
Vue.component('postalcode-input', PostalCodeInput);
Vue.component('company-search', CompanySearch);
Vue.component('user-search', UserSearch);

const i18n = new VueI18n({
    locale: 'nl_NL',
    silentTranslationWarn: true,
    messages: {
        nl_NL
    }
});

const app = new Vue({
    i18n,
    el: '#app'
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

$('textarea#editor').trumbowyg({
    svgPath: '/icons.svg'
});

// App

require('./app/actions.js');
require('./app/i18n.js');
require('./app/nbrogress.js');
require('./app/tables.js');
require('./app/tabs.js');

// Formatters

require('./formatters/delete.js');

$(document).ready(function() {
    var foundColumns = [];

    $('.data-table-new thead tr th').each(function (i, item) {
        foundColumns.push( {data: $(item).data('column'), orderable: $(item).data('sortable')} );
    });

    var dataTableAjax = $('.data-table-new').DataTable({
        language: {
            url: "//cdn.datatables.net/plug-ins/1.10.19/i18n/Dutch.json",
            processing: "Loading..."
        },
        processing: true,
        serverSide: true,
        ajax: {
            'url': $('.data-table').data('url'),
            "data": function( result ) {
                var submitData = {};

                submitData['length'] = result.length;
                submitData['search'] = result.search.value;
                submitData['order'] = result.columns[result.order[0].column].data;
                submitData['dir'] = result.order[0].dir;
                submitData['start'] = result.start;

                $.each($('.custom-search').serializeArray(), function(key, field) {
                    if (field && field.value) {
                        submitData[field.name] = field.value;
                    }
                });
                return submitData;
            }
        },
        order: [[ 0, "desc" ]],
        columns: foundColumns,
        stateSave: true,
        pageLength: 25,
        select: true
    });

    $("form.custom-search :input").change(function() {
        dataTableAjax.draw();
    });

    $('.data-table').DataTable({
        language: {
            url: "//cdn.datatables.net/plug-ins/1.10.19/i18n/Dutch.json"
        },
        stateSave: true,
        pageLength: 25,
        select: true,
        order: [[ 0, "desc" ]],
        aaSorting: [[0, 'desc']]
    });

    $('select.select2').select2();

    $(document).on('click', '.openModal', function() {
        $($(this).data('target') + ' form').attr('action', $(this).data('route'));
        $($(this).data('target')).modal('show');
    });

    $('.mobile-nav').click(function(event) {

        event.preventDefault();

        $(".mobile-nav div").each(function(){
            $(this).toggleClass('change');
        });

        if ($( "div" ).hasClass( "open" )) {
            $('body').removeClass('lock-scroll');
            $(".sidebar-left").removeClass("d-none");
        } else {
            $('body').toggleClass('lock-scroll');
            $(".sidebar-left").toggleClass("d-none");
        }
    });

    $( ".company-search-holder input[name='change_company']").keyup(function() {

        $('ul.linked-companies  li.no-results').addClass('d-none');

        var that = this, allListElements = $('ul.linked-companies > li.searchable');

        var matchingListElements = allListElements.filter(function(i, li){
            var listItemText = $(li).text().toUpperCase(), searchText = that.value.toUpperCase();
            return ~listItemText.indexOf(searchText);
        });
        allListElements.hide();
        matchingListElements.show();

        $('ul.linked-companies').addClass('d-block');

        if (matchingListElements.length === 0) {
            $('ul.linked-companies li.no-results a').html("");
            $('ul.linked-companies li.no-results a').html('Geen resultaten gevonden voor: ' + $(this).val());
            $('ul.linked-companies li.no-results').removeClass('d-none');
            $('ul.linked-companies li.no-results').removeAttr('style');
        }
    });

    $(document).on("click", "ul.linked-companies li.searchable", function(e) {
        e.preventDefault();
        window.location.replace($(this).find("a").attr("href"));
    });

    $( ".company-search-holder input[name='change_company']").focus(function() {
        $('.linked-companies').addClass('d-block');
    });

    $( ".company-search-holder input[name='change_company']").focusout(function() {
        window.setTimeout(function() {
            $('.linked-companies').removeClass("d-block")
        }, 1000);
    });
});
