NProgress.configure({
    minimum: 0.08,
    easing: "ease",
    speed: 500,
    showSpinner: false
});

$(window).bind('beforeunload',function() {
    NProgress.start();
});

$(window).on("load",function() {
    NProgress.done();
});
