<template>
    <div class="col-sm-6 col-lg-6 col-xl-3 mb-4">

        <div class="card cursor-pointer h-100" @click="go">
            <img class="card-img-top" :src="shift.impression_picture" :alt="shift.company.name">
            <div class="card-orange-top-left">
                {{ shift.hourly_rate | currency('€', ',', 2).replace(',00', ',-') }} p/u
            </div>
            <div class="card-body">
                <div class="card-list-info">
                    <div class="d-flex">
                        <div class="icon mr-2">
                            <i class="far fa-map-marker"></i>
                        </div>
                        <div class="text">
                            <h5 class="card-title mb-0">{{ shift.company.name }}</h5>
                            <div>{{ shift.company.address }}</div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="icon mr-2">
                            <i class="far fa-calendar-alt"></i>
                        </div>
                        <div class="text">{{ shift.starts_at | moment('dd D MMMM | H:mm') }} - {{ shift.ends_at | moment('H:mm') }}</div>
                    </div>
                    <div class="d-flex">
                        <div class="icon mr-2">
                            <i class="far fa-suitcase"></i>
                        </div>
                        <div class="text">{{ shift.profession }}</div>
                    </div>

                    <div class="d-flex" v-if="!shift.company.has_factoring">
                        <i class="mt-2 d-block">Factoring uitbetaling niet mogelijk</i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['shift'],
        data () {
            return {

            }
        },
        methods: {
            go () {
                window.location.href = this.shift.url;
            }
        }
    }
</script>